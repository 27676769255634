@tailwind base;
@tailwind components;
@tailwind utilities;


@media screen and (max-width: 768px){
  .tabs:not(:last-child){
  border-bottom: 2px solid rgb(232, 230, 230);
}

}

@layer base {
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');

  *{
    font-family: 'Inter', sans-serif;
    
  }  
  .navbar{
    background: transparent;
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 999;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem ;

  }
 .navbar.active {
  background-color: #fffafa;
  color: black;
  position: fixed;
  width: 100%;
  align-items: center;
  padding-top: 10px;
  box-shadow: rgb(131, 130, 130) 0px 2px 10px;
 }
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  .btn{
    border: none;
  } 
  .menu-item:hover{
    color: orangered;
  }

  .content {
    display: none;
  }
  .content h2{
    padding: 0px 0 5px 0px;
  }
  .active-content {
    display: block;
  }
 
}
